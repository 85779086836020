.avatar {
  height: var(--input-small-height);
  width: var(--input-small-height);
  border-radius: 200px;
  overflow: hidden;
  border: 1px solid var(--color-gray-200);
}

.avatar img {
  width: 100%;
}
