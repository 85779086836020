.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacer-small) * 0.5);
  align-items: stretch;
}

.input {
  border: 1px solid var(--input-border-color);
  border-radius: var(--input-medium-radius);
  height: var(--input-medium-height);
  line-height: var(--input-medium-height);
  padding: var(--input-medium-padding);
  background-color: var(--input-color);
  color: var(--input-text-color);
  font-size: var(--input-medium-font-size);
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  transition: var(--input-transition);
  outline: none;
}

.input:hover {
  border-color: var(--input-hover-border-color);
}

.input:focus, .input:active {
  border-color: var(--input-active-border-color);
  box-shadow: 0 0 0 1px var(--input-active-border-color);
}

.descriptionText {
  font-size: var(--font-size-small);
  color: red;
}

.small {
  border-radius: var(--input-small-radius);
  height: var(--input-small-height);
  line-height: var(--input-small-height);
  padding: var(--input-small-padding);
  font-size: var(--input-small-font-size);
}

.large {
  border-radius: var(--input-large-radius);
  height: var(--input-large-height);
  line-height: var(--input-large-height);
  padding: var(--input-large-padding);
  font-size: var(--input-large-font-size);
}

.rounded {
  border-radius: 100px;
}
