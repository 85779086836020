.result {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: calc(var(--spacer-base) * 1.5);
  max-width: 600px;
  margin: 0 auto;
}

.icon {
  font-size: 100px;
  margin: 0;
}

.title {
  margin: 0;
}


