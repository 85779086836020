.logo {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: auto;
}

.logoName {
  margin: 0;
  line-height: 1;
  white-space: nowrap;
}

.logo.small .logoName {
  font-weight: bold;
  font-size: 110%;
}
