.poster {
  border-radius: 10%/6%;
  overflow: hidden;
  object-fit: contain;
  aspect-ratio: 2 / 3;
  position: relative;
}

.poster:before {
  position: absolute;
  border-radius: 10%/6%;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: '🍿';
  display: grid;
  place-items: center;
  padding-top: 2px;
  font-size: 42px;
  text-align: center;
  background-color: var(--color-gray-200);
  background-position: center;
}

.poster > img {
  position: relative;
  z-index: 10;
  border-radius: 10%/6%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}
