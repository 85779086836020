.wrapper {
  position: relative;
}

.trigger {
  cursor: pointer;
}

.menu {
  min-width: 200px;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  right: 0;
  opacity: 0;
  transform: translateY(100%);
  visibility: hidden;
  transition: var(--transition-base);
  border-radius: var(--radius-base);
  border: 1px solid var(--color-gray-150);
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  background: white;
}

.menu.open {
  opacity: 1;
  visibility: visible;
}

.header {
  min-height: var(--input-height);
  padding: var(--spacer-small) var(--spacer-base);
  cursor: default;
  border-bottom: 1px solid var(--color-gray-100);
}

.item {
  height: var(--input-height);
  padding: var(--spacer-small) var(--spacer-base);
  cursor: pointer;
}

.item:hover {
  background-color: var(--color-gray-100);
}

