:root {
  --banner-bullet-size: 14px;
  --banner-backdrop-bg: 0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .7) 20%, rgba(0, 0, 0, .6) 30%,rgba(0, 0, 0, .5) 40%, rgba(0, 0, 0, 0) 60%;
  --mobile-banner-backdrop-bg: 0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .7) 50%, rgba(0, 0, 0, .6) 55%,rgba(0, 0, 0, .4) 80%, rgba(0, 0, 0, 0) 90%;
}

.container {
  width: 100%;
  width: calc(100% + calc(var(--container-spacer) * 2));
  margin-left: calc(var(--container-spacer) * -1);
}

.heading {
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: var(--container-spacer);
}

.slide {
  position: relative;
  aspect-ratio: 16 / 7.5;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  background-color: black;
}

.slide:before {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  background: rgba(0, 0, 0, 1);
  background: -moz-linear-gradient(var(--banner-backdrop-bg));
  background: -webkit-linear-gradient(var(--banner-backdrop-bg));
  background: linear-gradient(var(--banner-backdrop-bg));
}

.backdropImage {
  position: absolute;
  width: 100%;
  height: 100%;
}

.backdropImage > div {
  width: 100%;
  height: 100%;
  background-position: top center;
  background-size: cover;
}

.info {
  z-index: 2;
  margin: var(--container-spacer);
  margin-bottom: calc(var(--spacer-extra-large) * 1.5);
  color: white;
}

.title {
  max-width: 70%;
  position: relative;
  text-shadow: 1px 1px rgba(0, 0 ,0, .1);
}

.controls {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.overview {
  color: var(--font-color-muted);
  position: relative;
  font-size: calc(var(--font-size-base) + 20%);
  flex: 0 1 70%;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: var(--spacer-base);
}

.bullets {
  margin-top: var(--spacer-large);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-small);
}

.bullet {
  opacity: .5;
  flex-shrink: 0;
  height: var(--banner-bullet-size);
  width: var(--banner-bullet-size);
  background-color: var(--color-primary);
  border-radius: var(--banner-bullet-size);
  cursor: pointer;
  -webkit-transition: var(--transition-base);
  -moz-transition: var(--transition-base);
  -o-transition: var(--transition-base);
  transition: var(--transition-base);
}

.bullet:hover {
  opacity: 1;
}

.bulletActive {
  opacity: 1;
  width: 40px !important;
  border-radius: 10px;
}

.loaderBannerPage {
  aspect-ratio: 16 / 7.5;
  height: 100%;
  border-radius: 0 !important;
}

@media screen and (max-width: 768px) {
  .heading {
    margin-left: var(--container-spacer);
  }

  .slide, .slide:before {
    border-radius: 0;
    aspect-ratio: 10 / 12;
  }

  .slide:before {
    background: -moz-linear-gradient(var(--mobile-banner-backdrop-bg));
    background: -webkit-linear-gradient(var(--mobile-banner-backdrop-bg));
    background: linear-gradient(var(--mobile-banner-backdrop-bg));
  }

  .info {
    margin-bottom: var(--spacer-large);
  }

  .title {
    max-width: 100%;
  }

  .overview {
    flex: 0 1 100%;
    margin-bottom: var(--spacer-base);
    -webkit-line-clamp: 6;
  }

  .controls {
    flex-wrap: wrap;
  }

  .bullets {
    padding-left: var(--spacer-base);
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
  }

  .bullet {
    height: calc(var(--banner-bullet-size) + 1px);
    width: calc(var(--banner-bullet-size) + 1px);
  }

  .loaderBannerPage {
    aspect-ratio: 10 / 12;
  }
}
