.item {
  position: relative;
  aspect-ratio: 1 / 1.5;
}

.item:hover:not(.softLoading) .poster {
  transform: scale(1.05);
}

.medium {
  width: var(--show-size-base);
  min-width: var(--show-size-base);
}

.small {
  width: var(--show-size-small);
  min-width: var(--show-size-small);
}

.large {
  width: var(--show-size-large);
  min-width: var(--show-size-large);
}

.poster {
  transition: var(--transition-base);
}

.header {
  max-height: 60px;
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  flex-basis: content;
  text-overflow: ellipsis;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: default;
  white-space: normal;
}

.rating {
  margin-top: 4px !important;
  margin-left: 3px !important;
  font-size: 11px !important;
  flex-shrink: 0;
}

.categories {
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-small);
  color: var(--font-color-muted);
}

.softLoading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
}


