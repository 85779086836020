:root {
  --provider-item-width: 80px;
}

.providers {
  margin-bottom: var(--spacer-extra-large);
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: var(--spacer-small);
  margin-bottom: var(--spacer-base);
}

.heading {
  margin-bottom: 0;
}

.items {
  display: flex;
  gap: calc(var(--spacer-large) * 1.2);
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: var(--spacer-large);
}

.item {
  width: var(--provider-item-width);
  gap: var(--spacer-small);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
}

.logo {
  width: var(--provider-item-width);
  border-radius: var(--radius-base);
  border: 1px solid var(--color-gray-150);
}

.providerName {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.disclaimer {
  margin-bottom: var(--spacer-base);
}

.mobile .items {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--provider-item-width));
  justify-content: space-between;
  grid-gap: var(--spacer-base);
}
