.images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacer-small);
}

.image {
  cursor: pointer;
  width: 100px;
  border-radius: var(--radius-base);
}

.moreTitle {
  position: relative;
  color: white;
  z-index: 10;
}

.moreBackdrop {
  box-sizing: border-box;
  text-align: center;
  display: grid;
  place-items: center;
  padding: var(--spacer-small);
}

.moreBackdrop:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}
