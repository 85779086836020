.button {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  gap: calc(var(--spacer-small) * .6);
  justify-content: center;
  border: 2px solid var(--button-color);
  height: var(--button-medium-height);
  line-height: var(--button-medium-height);
  padding: var(--button-medium-padding);
  border-radius: var(--radius-base);
  background-color: var(--button-color);
  color: var(--button-text-color);
  cursor: pointer;
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  transition: var(--transition-base);
  text-transform: uppercase;
  overflow: hidden;
}

.button:disabled {
  background-color: var(--color-gray-200) !important;
  border-color: var(--color-gray-200);
  color: var(--color-gray-300);
  cursor: no-drop;
}

.button:hover {
  background-color: var(--button-color-hover);
}

.button:active {
  transform: scale(0.95);
  background-color: var(--button-color-hover);
}

.small {
  border-radius: calc(var(--radius-base) - 2px);
  height: var(--button-small-height);
  line-height: var(--button-small-height);
  padding: var(--button-small-padding);
  font-size: calc(var(--font-size-base) * .8);
}

.large {
  height: var(--button-large-height);
  line-height: var(--button-large-height);
  padding: var(--button-large-padding);
}

.pill {
  border-radius: 100px !important;
}

.rounded {
  border-radius: 50% !important;
  aspect-ratio: 1 / 1 !important;
}

.secondary {
  border-color: var(--button-secondary-color);
  background-color: var(--button-secondary-color);
  color: var(--button-secondary-text-color) !important;
}

.secondary:hover {
  color: var(--button-secondary-text-color-hover) !important;
  border-color: var(--button-secondary-color-hover);
  background-color: var(--button-secondary-color-hover);
}

.outlined {
  color: var(--button-outlined-text-color);
  border-color: var(--button-outlined-border-color);
  background-color: var(--button-outlined-color);
}

.outlined:hover {
  color: var(--button-outlined-text-color-hover) !important;
  border-color: var(--button-outlined-border-color-hover) !important;
  background-color: var(--button-outlined-color-hover) !important;
}

.success {
  border-color: var(--button-success-color);
  background-color: var(--button-success-color);
  color: var(--button-success-text-color);
}

.success:hover {
  color: var(--button-success-text-color-hover) !important;
  border-color: var(--button-success-color-hover);
  background-color: var(--button-success-color-hover);
}

.active, .active:hover {
  color: var(--button-active-text-color) !important;
  border-color: var(--button-active-border-color) !important;
  background-color: var(--button-active-color) !important;
}

.outlined:hover {
  border-color: var(--button-secondary-color-hover);
  background-color: var(--button-secondary-color-hover);
  color: var(--button-secondary-text-color-hover);
}

.secondary:hover {
  color: var(--button-text-color);
}

.icon {
  font-size: calc(var(--font-size-base) * 1.5);
  line-height: 0;
}

.iconLoader {
  animation: rotating 2s linear infinite;
}

.isLoading {
  cursor: no-drop;
  pointer-events: none;
}

.isLoading::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .4);
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
