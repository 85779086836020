.page {
  margin: 0;
  min-height: 100vh;
  max-width: 100vw;
  display: grid;
  padding-top: calc(var(--header-height) + var(--spacer-large));
  grid-template-rows: 1fr max-content;
  -webkit-animation-name: pageTransition;
  animation-name: pageTransition;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.content {
  grid-row-start: 1;
  grid-row-end: 1;
  max-width: 100vw;
}

.footer {
  grid-row-start: 2;
  grid-row-end: 2;
  max-width: 100vw;
}

@-webkit-keyframes pageTransition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pageTransition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
