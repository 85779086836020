.cast {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--spacer-large);
}

.person {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.name {
  margin-top: var(--spacer-small);
  font-size: var(--font-size-small);
  width: calc(var(--avatar-size) * 1.2);
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mobile .name {
  font-size: var(--font-size-small) !important;
}
