.images {
  display: flex;
  text-align: center;
  overflow: auto;
  margin: 0 auto;
  max-width: 100%;
  gap: var(--spacer-small);
  padding: 0 var(--container-spacer);
  box-sizing: border-box;
}

.image {
  width: 100px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: var(--radius-base);
}

.image:first-child {
  margin-left: auto;
}

.activeImageBackdrop {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  box-sizing: border-box;
  z-index: 1000;
  transition: var(--transition-base);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  place-content: center;
  overflow-y: auto;
  gap: var(--spacer-large);
  padding: var(--container-spacer) 0;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 5%, rgba(0,0,0,0.20214023109243695) 100%);
}

.activeImageBackdrop.open {
  opacity: 1;
  visibility: visible;
}

.activeImage {
  border-radius: var(--radius-base);
}
