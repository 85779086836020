.header {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  padding: 0 var(--container-spacer);
  grid-template-columns: auto 1fr auto auto;
  grid-template-areas: "logo menu search user";
  align-items: center;
  height: var(--header-height);
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  gap: var(--spacer-extra-large);
  border-bottom: 1px solid var(--color-gray-100);
}

.logo {
  grid-area: logo;
  z-index: 11;
  position: relative;
}

.menu {
  grid-area: menu;
}

.user {
  grid-area: user;
}

.menuCheckbox {
  display: none;
}

.menuSandwichButton {
  display: none;
  cursor: pointer;
}

.menuSandwichButton > * {
  margin: 0;
  line-height: 1;
  height: 38px;
}

.menuItems {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacer-large);
}

.menuItem {
  color: var(--font-color-base);
}

.darkBackground {
  background: rgba(0,0,0, .7);
  border-bottom: 1px solid transparent;
}

.darkBackground .menuSandwichButton {
  color: white;
}

.darkBackground .menuItem {
  color: white;
}

.darkBackground .search {
  color: white;
}

.menuActive {
  font-weight: bold;
  color: var(--color-primary) !important;
}

.mobile.header {
  gap: var(--spacer-small);
  grid-template-columns: auto auto 1fr auto;
  grid-template-areas: "menu search logo user";
}

.mobile .logo {
  position: fixed;
  left: 50%;
  top: 35px;
  transform: translateX(-55%);
  grid-area: logo;
}

.mobile .search {
  margin-top: 9px;
}

.mobile .menuSandwichButton {
  display: block;
}

.mobile .menuItems {
  left: calc(var(--container-spacer) * -1);
  top: 0;
  margin-top: var(--header-height);
  position: absolute;
  display: flex;
  opacity: 0;
  visibility: hidden;
  padding: 0 var(--container-spacer);
  width: calc(100% + calc(var(--container-spacer) * 2));
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  background: #fff;
  transition: var(--transition-base);
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.mobile .menuItem {
  width: 100%;
  color: var(--font-color-base);
  padding: var(--spacer-small) var(--container-spacer);
  border-bottom: 1px solid var(--color-gray-100);
}

.mobile .menuCheckbox:checked ~ .menuItems {
  opacity: 1;
  visibility: visible;
}
