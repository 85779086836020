@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0 100%;
  }
}

.loader > .circle,
.loader > .rectangle,
.loader > .paragraph > .paragraphLine {
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, var(--color-gray-200) 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
}

.paragraph {
  gap: var(--spacer-base);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.paragraphLine {
  flex: 1;
  border-radius: var(--font-size-base);
}

.rectangle {
  min-height: var(--font-size-small);
  border-radius: var(--radius-base);
}

.circle {
  aspect-ratio: 1 / 1;
  border-radius: 100%;
}

