.container {
  max-width: 400px;
  position: relative;
  margin: 0 auto;
  display: grid;
  place-items: center;
  justify-content: center;
}

.icon {
  font-size: 80px;
}

.description {
  text-align: center;
}

.form {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  gap: var(--spacer-large);
}

.actions {
  margin-top: var(--spacer-large);
}

.alreadyHaveAccount {
  margin-top: var(--spacer-large);
}
