.flags {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: calc(var(--spacer-small) / 2);
  line-height: 1;
}

.flag {
  cursor: default;
}
