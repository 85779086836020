.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pagination {
  margin: var(--spacer-large) 0 var(--spacer-extra-large);
}
