:root {
  /* Colors */
  --color-primary: var(--color-red-800);
  --color-gray-100: #eef5fa;
  --color-gray-150: #dcdfe4;
  --color-gray-200: #9CA6AD;
  --color-gray-300: #4E6E81;
  --color-gray-400: #002233;
  --color-error: var(--color-red-600);
  --color-success: var(--color-green-900);

  --color-red-100: #ffe6e6;
  --color-red-200: #ffcdcd;
  --color-red-300: #ff8181;
  --color-red-400: #ff6868;
  --color-red-500: #ff4f4f;
  --color-red-600: #ff3535;
  --color-red-700: #ff1c1c;
  --color-red-800: #ff0303;
  --color-red-900: #e60303;

  --color-green-100: #eff8f1;
  --color-green-200: #e8f7ea;
  --color-green-300: #b9e6bf;
  --color-green-400: #8bd594;
  --color-green-500: #5cc469;
  --color-green-600: #45bb53;
  --color-green-700: #2db33e;
  --color-green-800: #0fa521;
  --color-green-900: #128820;

  /* Page */
  --container-spacer: var(--spacer-extra-large);

  /* Breakpoints */
  --small-device: 724px;

  /* Header */
  --header-height: 90px;

  /* Radius */
  --radius-small: 6px;
  --radius-base: 10px;
  --radius-large: 18px;
  --radius-extra-large: 24px;

  /* Spacers */
  --spacer-small: 12px;
  --spacer-base: 16px;
  --spacer-large: 32px;
  --spacer-extra-large: 64px;

  /* Transitions */
  --transition-base: all .2s ease-in-out;

  /* Fonts */
  --font-size-base: 18px;
  --font-size-small: 14px;
  --font-size-large: 32px;
  --font-size-extra-large: 52px;
  --font-family: 'Inter', sans-serif;
  --font-color-base: var(--color-gray-400);
  --font-color-muted: var(--color-gray-200);

  /* Buttons */
  --button-small-height: 44px;
  --button-small-padding: 0 calc(var(--spacer-small) + 2px);
  --button-medium-height: 58px;
  --button-medium-padding: 0 calc(var(--spacer-base) + 6px);
  --button-large-height: 76px;
  --button-large-padding: 0 calc(var(--spacer-large) * .8);
  --button-text-color: white;
  --button-color: var(--color-red-800);
  --button-color-hover: var(--color-red-900);
  --button-active-text-color: white;
  --button-active-color: var(--color-red-900);
  --button-active-border-color: var(--color-red-900);
  --button-secondary-color: var(--color-gray-100);
  --button-secondary-color-hover: var(--color-gray-150);
  --button-secondary-text-color: var(--font-color-base);
  --button-secondary-text-color-hover: var(--font-color-base);
  --button-outlined-color: white;
  --button-outlined-border-color: var(--color-gray-150);
  --button-outlined-color-hover: var(--color-red-200);
  --button-outlined-border-color-hover: var(--color-red-200);
  --button-outlined-text-color: var(--font-color-base);
  --button-outlined-text-color-hover: var(--color-red-900);
  --button-success-color: var(--color-green-800);
  --button-success-color-hover: var(--color-green-900);
  --button-success-text-color: white;
  --button-success-text-color-hover: white;

  /* Inputs */
  --input-small-height: 44px;
  --input-small-padding: 0 calc(var(--spacer-small) + 2px);
  --input-small-radius: var(--radius-base);
  --input-small-font-size: var(--font-size-small);
  --input-medium-height: 58px;
  --input-medium-padding: 0 calc(var(--spacer-base) + 6px);
  --input-medium-radius: var(--radius-base);
  --input-medium-font-size: var(--font-size-base);
  --input-large-height: 76px;
  --input-large-padding: 0 var(--spacer-large);
  --input-large-radius: var(--radius-base);
  --input-large-font-size: var(--font-size-large);
  --input-transition: var(--transition-base);
  --input-color: white;
  --input-border-color: var(--color-gray-200);
  --input-hover-border-color: var(--color-gray-300);
  --input-active-border-color: var(--color-primary);
  --input-text-color: var(--font-color-base);

  /* Shows, carousels and lists */
  --show-size-small: 150px;
  --show-size-base: 200px;
  --show-size-large: 300px;

  /* Images */
  --avatar-size: 58px;
}

@supports (font-variation-settings: normal) {
  :root {
    --font-family: 'Inter var', sans-serif;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --container-spacer: calc(var(--spacer-base) * 1.5);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--font-color-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: var(--transition-base);
}

a:hover {
  color: var(--color-red-600);
}

.Toastify__toast {
  margin: var(--spacer-base);
  box-sizing: border-box;
  border-radius: var(--radius-base);
  line-height: 1.5;
}
